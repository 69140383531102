@import "../includes/base.css";

.t1-defaultPageContent {
  h1, h2, h3, h4, h5, h6 {
    @apply mb-3;

    &:not(:first-child) {
      @apply mt-6;
    }
  }
  p, ul, ol {
    &:not(:last-child) {
      @apply mb-3;
    }
  }

  ul, ol {
    @apply pl-8;
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  a {
    @apply transition-colors text-primary hover:text-primary-darker underline;
  }
}
